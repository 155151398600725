import { ZatTables } from '../../utilities/zattables.js';
import './datatables-additional.js';
import i18n from '../../utilities/i18n.js';

/**
 * DataTables Default Settings and Render Functions
 */

/**
 * Extend jQuery
 */
$.fn.extend({
	/**
	 * Wrapper for DataTable() which sets some config values after parsing the DOM
	 * E.g.: 	$('mytable').ZatTable()
	 *			$('mytable').ZatTable({ config: 'value' });
	 * Attempts to nicely merge the config passed and that generated, but may need some tweaking.
	 */
	ZatTable: function(config) {
		this.each(function(k, v) {
			const zt = new ZatTables($(this));
			var options = zt.options();

			if (config) {
				$.each(options, function(key, value) {
					if (Array.isArray(value) && (typeof config[key] != 'undefined')) {
						$.merge(options[key], config[key]);
						delete config[key];
					}
				});
				$.extend(true, options, config ? config : null);
			}

			$(this).data('ztconfig', config);

			// Initialise the table with our options
			$(this).DataTable(options);

			// table.on('draw', function() {
			// 	var visibleColumns = table.columns(':visible');
			// 	var visibleColumnIndices = visibleColumns.indexes().toArray();

			// 	table.rows({page: 'current'}).every(function(rowIdx, tableLoop, rowLoop) {
			// 		var cells = $(this.node()).find('td');
			// 		cells.each(function() {
			// 			var cellIndex = $(this).index();
			// 			var visibleCellIndex = visibleColumnIndices[cellIndex];
			// 			if (typeof visibleCellIndex != 'undefined') {
			// 				var header = table.column(visibleCellIndex).header();
			// 				if ($(header).attr('data-data')) {
			// 					var dataData = $(header).attr('data-data');
			// 					var rowData = table.row(rowIdx).data();
			// 					var id = rowData.id;
			// 					// Use the data-data attribute and id to set the data-qa attribute
			// 					$(this).attr('data-qa', dataData + '_' + id);
			// 				}
			// 			}
			// 		});
			// 	});
			// });
		});
		return this;
	},
	target: function() {
		let $target;
		this.each(function(k, v) {
			let target = $(this).data('target');
			if (typeof target == 'undefined') {
				$target = $('table.zat-table').eq(0);
				if ($target.length == 0) {
					$target = false;
				}
			} else {
				$target = $('#' + target);
			}
		});
		return $target;
	}
});

// Stops errors in pages not including DataTables. Maybe we should only include this if DataTables is included
if (typeof $.fn.dataTable != 'undefined') {

	// Stop the alert, we'll handle in zattables.js with the 'error.dt' event handler
	$.fn.dataTable.ext.errMode = 'none';

	var button = {
		extend: 'pdf',
		name: 'pdf',
		exportOptions : {
			columns: function(idx, data, node) {
				if ($(node).data('selector') !== undefined) {
					return false;
				}
				if ($(node).data('actions') !== undefined) {
					return false;
				}
				if ($(node).data('responsive') !== undefined) {
					return false;
				}
				let tableId = $(node).closest('table').attr('id');
				return tableId === undefined ? false : $(tableId).DataTable().column(idx).visible();
			},
			modifier: {
				order:  'current',
				page:   'all',
				search: 'applied',
				focused: undefined,
				selected: undefined
			}
		},
		filename: function() {
			return jQuery.fn.dataTable.zt.filename;
		},
		title: function() {
			return jQuery.fn.dataTable.zt.title;
		}
	};

	var pdf = Object.assign({}, button);
	pdf.name = 'pdf';
	pdf.extend = 'pdf';
	pdf.orientation = 'landscape';
	var pdfOptions = Object.assign({}, button.exportOptions);
	pdfOptions.orthogonal = 'export';
	pdf.exportOptions = pdfOptions;

	var csv = Object.assign({}, button);
	csv.name = 'csv';
	csv.extend = 'csv';
	var csvOptions = Object.assign({}, button.exportOptions);
	csvOptions.orthogonal = 'csv';
	csvOptions.customizeData = function(data) {
		$.each(data.body, function(i, line) {
			data.body[i] = line.map(value => value == null ? '' : value);
		});
	};
	csv.exportOptions = csvOptions;

	var excel = Object.assign({}, button);
	excel.name = 'excel';
	excel.extend = 'excel';
	var excelOptions = Object.assign({}, button.exportOptions);
	excelOptions.orthogonal = 'csv';
	excel.exportOptions = excelOptions;

	var buttons = ['colvis', pdf, csv, excel];

	$.extend($.fn.dataTable.defaults, {
		"ordering" :  true,
		"colReorder" : true,
		"serverSide" : true,
		"stateSave" : true,
		"stateDuration" : 0,
		"lengthMenu" : [10, 25, 50, 100],
		"pagingType" : "simple_numbers",
		"processing" : true,
		"language": {
			"loadingRecords": "",
			"processing" : ""
		},
		"dom": "rt" + "<'data-table-footer'<'grid-x align-middle'<'cell small-12 large-auto bl'p><'cell small-12 large-shrink br'>>>",
		"buttons" : buttons
	});

	jQuery.fn.dataTable.render.titlecase = function(data, type, row) {
		const str = data || '';
		const words = str.toLowerCase().split(' ');
		return words.map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ');
	};

	jQuery.fn.dataTable.render.uppercase = function(data, type, row) { return (data || '').toUpperCase(); };

	jQuery.fn.dataTable.render.boolean = function(data, type = null, row = null, meta = null) {
		if (jQuery.fn.dataTable.render._isRenderType(meta)) {
			if (false === ['display', 'export', 'filter'].includes(type)) {
				return data ? 1 : 0;
			}
			return jQuery.fn.dataTable.render._boolean.call('No,Yes', data, type);
		} else {
			return jQuery.fn.dataTable.render._boolean.bind(data);
		}
	}
	jQuery.fn.dataTable.render._boolean = function(data, type) {
		const parts = this.split(',').map(v => $.trim(v));
		return (data && (data != 0)) ? parts[1] : parts[0];
	};

	jQuery.fn.dataTable.render.uid = 0;

	jQuery.fn.dataTable.zt = {};

	/**
	 * Format a column as a dropdown.
	 * The currency object can either be passed as a lookup ("currency", single item array) or as a column ("currency").
	 *
	 * @param   string      source            The id of the html element to use as the dropdown's source.
	 * @param   string      lookup            The name of the lookup to use for the row's value. Optional.
	 */
	jQuery.fn.dataTable.render.dropdown = function(source, lookup = null) {
		var [source, lookup] = [source, lookup];
		return function(data, type, row) {
			if (lookup) {
				data = jQuery.fn.dataTable.render._lookup(lookup, data);
			}
			let id = source + '_' + (++jQuery.fn.dataTable.render.uid);
			var $dropdown = $('#' + source).clone();
			$dropdown
				.children('button')
					.text(data)
					.attr('data-toggle', id)
					.end()
				.find('div')
					.attr('id', id)
					.addClass('dropdown-pane')
					.attr('data-dropdown', '');
			return jQuery.fn.dataTable.render._untokenise($dropdown.html(), row);
		};
	};

	/**
	 * Formats a column as a date.
	 * The format to use can be passed as a parameter [1]. If no parameter is set the default will be used [2].
	 * See moment.js for format string.
	 * E.g. <th data-data="entryDate" data-render="datetime" data-render-params="DD/MM/YY" ... >
	 * E.g. <th data-data="entryDateTime" data-render="datetime" ... >
	 *
	 * @param   mixed       data              Either the column data [2] or the format to use [1], depending on how the function is being called.
	 * @param   string      type              Either the call type [2] or null [1], depending on how the function is being called.
	 * @param   object      row               Either the row data [2] or null [1], depending on how the function is being called.
	 * @param   object      meta              Either the meta data [2] or null [1], depending on how the function is being called.
	 */
	jQuery.fn.dataTable.render.datetime = function(data, type = null, row = null, meta = null) {

		if (jQuery.fn.dataTable.render._isRenderType(meta)) {
			if (false === ['display', 'export', 'filter'].includes(type)) {
				return data;
			}
			return jQuery.fn.dataTable.render._datetime.call('DD/MM/YYYY HH:mm:ss', data, type);
		} else {
			return jQuery.fn.dataTable.render._datetime.bind(data);
		}
	}

	// let formatted = jQuery.fn.dataTable.render._datetime.call('YYYY-MM-DD', '1970-02-28 10:00:00');
	jQuery.fn.dataTable.render._datetime = function(data, type) {
		if (false === ['display', 'export', 'filter'].includes(type)) {
			return data;
		}
		if (data == null || data.length == 0 || /^0000/.test(data)) {
			return jQuery.fn.dataTable.render.NA;
		}
		return moment(data).format(this);
	}

	/**
	 * Format a number as currency.
	 * The currency object can either be passed as a lookup ("currency", single item array) or as a column ("currency").
	 * Location should be either 'row' to use the column value, or 'lookup' to use a lookup table.
	 *
	 * @param   string      location          The location of the currency object, either 'row' or 'lookup'.
	 * @param   object      currency          The currency object
	 */
	jQuery.fn.dataTable.render.currency = function (location) {
		var location = location;
		return function(data, type, row) {
			if ((data == null) || (data.length == 0)) {
				return '';
			}
			if (false === ['display', 'export', 'filter'].includes(type)) {
				return data;
			}

			var response = Number(data).toFixed(2);
			var currency = false;
			if (location == 'row') {
				currency = row.currency;
			} else {
				currency = jQuery.fn.dataTable.render._lookup('currency', 0);
			}
			if (typeof currency == 'object') {
				var before = currency.htmlBeforeSymbol ? currency.htmlBeforeSymbol : (currency.before ? currency.before : '');
				var after = currency.htmlAfterSymbol ? currency.htmlAfterSymbol : (currency.after ? currency.after : '');
				response = before + response + after;
			}

			if (row.refund == 1) {
				response = '-'+response;
			}
			return response;
		};
	};

	/**
	 * Pass a url using {tokens} and this will convert the cell into a hyperlink.
	 * E.g. <th data-data="fieldName" data-render="linkify" data-render-params="/foo/bar/{id}?active={isActive}" ... >
	 * To use external links, amend the data-render-params attr to the following: data-render-params="[&quot;/foo/bar/{id}&quot;,true]"
	 *
	 * @param   string      format          The text to parse
	 * @param   boolean     openInNewTab    True of false if we are opening this link in external tab
	 */
	jQuery.fn.dataTable.render.linkify = function (format, openInNewTab = false) {
		var [format, openInNewTab] = [format, openInNewTab];
		return function (data, type, row) {
			if (type !== "display") {
				return data;
			}
			var link = jQuery.fn.dataTable.render._untokenise(format, row);
			if (data === null || data.length == 0) {
				data = jQuery.fn.dataTable.render.NA;
			}
			return '<a ' + (openInNewTab ? 'target="_blank" ' : '') + 'href="' + link + '">' + data + '</a>';
		};
	};

	/**
	 * Converts an id into a value using a lookup table.
	 * E.g. <th data-data="salutation" data-render="lookup" data-render-params="list_salutations" ... >
	 *
	 * @param   string      lookup          The name of the lookup to use.
	 *
	 */
	jQuery.fn.dataTable.render.lookup = function(lookup, empty = '') {
		var [lookup, empty] = [lookup, empty];
		return function (data, type, row) {
			if (typeof type == 'undefined') {
				return data;
			}
			if (['filter'].includes(type)) {
				return data;
			}
			return jQuery.fn.dataTable.render._lookup(lookup, data, empty);
		};
	};

	/**
	 * Renders a full name from it's component parts.
	 * E.g. <th data-data="lastName" data-render="fullname" data-render-params="[&quot;salutation&quot;,&quot;firstName&quot;,&quot;lastName&quot;]" ... >
	 *
	 * @param   string      salutation      The column name for the salutation. Can be in text or id format.
	 * @param   string      first           The column name for the first name.
	 * @param   string      last            The column name for the last name.
	 *
	 */
	jQuery.fn.dataTable.render.fullname = function(salutation, first, last) {
		var [salutation, first, last] = [salutation, first, last];
		return function (data, type, row) {
			if (typeof row[salutation] == 'undefined') {
				row[salutation] = '';
			}
			var name = [], elements;
			if (type == 'sort') {
				elements = [last, first, salutation];
			} else {
				elements = [salutation, first, last];
			}
			if (!isNaN(row[salutation])) {
				let lookup = jQuery.fn.dataTable.render._lookup('list_salutations', row[salutation]);
				if (lookup == row[salutation]) {
					lookup = '';
				}
				row[salutation] = lookup;
			}
			for (const key of elements) {
				if (row[key] != undefined) {
					if (row[key].toString().length) {
						name.push(row[key]);
					}
				}
			}
			return name.join(' ');
		};
	}

	/**
	 * Pass the field to use in place of this one. Useful when you want to deal with ids but display the text value.
	 * E.g. <th data-data="contactId" data-render="usurper" data-render-params="contactName" ... >
	 * Will handle an object as the source, e.g: contact.name.
	 *
	 * @param   string      field           The name of the column to use in its place.
	 */
	jQuery.fn.dataTable.render.usurper = function(field) {
		let parts = field.split('.');

		return function (data, type, row) {
			let response = row;
			if (true === ['display', 'export', 'csv'].includes(type)) {
				$.each(parts, function(k, part) {
					response = response[part];
				});
				return response;
			}
			return data; // This is a unformatted data
		};
	};

	/**
	 * Renders an formatting N/A for data that isn't available.
	 *
	 * @param   mixed       data            The column value.
	 * @return  string                      The value to display.
	 */
	jQuery.fn.dataTable.render.formatNullData = function(data) {
		if (!data) {
			return jQuery.fn.dataTable.render.NA;
		}
		return data;
	}

	/**
	 * Renders an address object.
	 *
	 * @param   mixed       data            The column value.
	 * @param   string      type            The render type.
	 * @param   array       row             An array of row data.
	 * @return  string                      The value to display.
	 */
	jQuery.fn.dataTable.render.address = function(data, type, row) {
		if (type == 'display') {
			return data.join('<br />');
		}
		return data.join(', ');
	};

	/**
	 * Renders a list of IDs.
	 *
	 * @param   mixed       data            The column value.
	 * @param   string      type            The render type.
	 * @param   array       row             An array of row data.
	 * @return  string                      The value to display.
	 */
	jQuery.fn.dataTable.render.ids = function(data, type, row) {
		return '@' + data.join('@') + '@';
	};

	/**
	 * Renders an activity status as an icon.
	 *
	 * @param   mixed       data            The column value.
	 * @param   string      type            The render type.
	 * @param   array       row             An array of row data.
	 * @return  string                      The value to display.
	 */
	jQuery.fn.dataTable.render.status = function(data, type, row) {
		if (type == 'display') {
			let status = data == 1 ? 'active' : 'inactive';
			let Status = data == 1 ? 'Active' : 'Inactive';
			return `<img src="/assets/img/icons/icon-indicator-${status}.svg" alt="${Status}" class="icon-status-indicator">`
		} else if(true === ['export', 'csv'].includes(type)) {
			return data == 1 ? 'Active' : 'Inactive';
		} else {
			return data;
		}
	};

	/**
	 * Renders an activity status status as an selector.
	 *
	 * @param   mixed       data            The column value.
	 * @param   string      type            The render type.
	 * @param   array       row             An array of row data.
	 * @return  string                      The value to display.
	 */
	jQuery.fn.dataTable.render.selector = function (data, type, row) {
		if (type == 'display') {
			if (data == 1) {
				return `<label><span class="sr-only">Select Row</span><input class="selector" type="checkbox" value="1" /></label>`;
			} else {
				return `<label><span class="sr-only">Select Row</span><input class="selector" type="checkbox" value="0" /></label>`;
			}
		} else if (true === ['export', 'csv'].includes(type)) {
			return data == 1 ? 'Active' : 'Inactive';
		} else {
			return data;
		}
	};

	/**
	 * Format telephone numbers using https://github.com/jackocnr/intl-tel-input
	 *
	 * @param   mixed       data            The column value.
	 * @param   string      type            The render type.
	 * @param   array       row             An array of row data.
	 * @return  string                      The value to display.
	 */
	jQuery.fn.dataTable.render.telephone = function(data, type, row) {
		if(!data) {
			return jQuery.fn.dataTable.render.NA;
		}
		let format = 2; // NATIONAL
		if (['filter', 'sort', 'csv'].includes(type)) {
			format = 0; // E164
		}
		return window.intlTelInputUtils.formatNumber(data, null, format);
	};

	/**
	 * Truncates a string to a given number of characters.
	 * E.g. <th data-data="description" data-render="truncate" data-render-params="100" ... >
	 * A default of 100 characters will be used if you omit the data-render-params attribute.
	 *
	 * @param   mixed       data 			Either the column data [2] or the maximum characters [1], depending on how the function is being called.
	 * @param   string      type 			Either the call type [2] or null [1], depending on how the function is being called.
	 * @param   object      row 			Either the row data [2] or null [1], depending on how the function is being called.
	 * @param   object      meta 			Either the meta data [2] or null [1], depending on how the function is being called.
	 */
	jQuery.fn.dataTable.render.truncate = function(data, type = null, row = null, meta = null) {
		if (jQuery.fn.dataTable.render._isRenderType(meta)) {
			if (false === ['display', 'sort'].includes(type)) {
				return data;
			}
			return jQuery.fn.dataTable.render._truncate.call(100, data, type);
		} else {
			return jQuery.fn.dataTable.render._truncate.bind(data);
		}
	}

	/**
	 * Renders a comma separated list of values from the given elements
	 * E.g. <th data-data="test" data-render="commaseparated" data-render-params="[&quot;siteName&quot;,&quot;siteCode&quot;]" ... >
	 *
	 * @param   string       Multiple arguments supplied, will attach them if not
	 *                       empty and join with commas
	 *
	 */
	jQuery.fn.dataTable.render.commaseparated = function(...args) {
		return function (data, type, args) {
			return Object.values(data).filter(function (ele) {
				return ele != null;
			}).join(', ');
		};
	}

	/**
	 * Renders a file size using the most appropriate unit.
	 * Ported from Attachments lib formatBytes()
	 *
	 * @param  integer data The filesize in bytes.
	 *
	 */
	jQuery.fn.dataTable.render.filesize = function(data, type, row) {
		if (data.length == 0) {
			return null;
		}
		const suffixes = [
			i18n.__('filesizes.b'),
			i18n.__('filesizes.kb'),
			i18n.__('filesizes.mb'),
			i18n.__('filesizes.gb'),
			i18n.__('filesizes.tb')
		];
		const base = Math.log(data) / Math.log(1024);
		return Math.round(Math.pow(1024, base - Math.floor(base)), 2) + suffixes[Math.floor(base)];
	}

	/**
	 * Renders an array of tags.
	 *
	 * @param  mixed  data The column value.
	 * @param  string type The render type (display, filter, sort, export, csv).
	 * @param  array  row   The full row of data.
	 * @return string
	 */
	jQuery.fn.dataTable.render.tags = function(data, type, row) {
		if (data == null || data.length == 0) {
			return '';
		}
		switch (type) {
			case 'display':
				return data.join(', ')
			case 'export':
			case 'csv':
				return data.join(',');
			default:
				return '@' + data.join('@') + '@';
		}
	}

	/**
	 * Handles the actual truncating for the truncate function.
	 * E.g.: let short = jQuery.fn.dataTable.render._truncate.call(50, long);
	 * @param   mixed       data 			The data to truncate.
	 * @param   string 		type 			The render type (display, sort, etc.).

	 */
	jQuery.fn.dataTable.render._truncate = function(data, type) {
		if (data == null || data.length == 0) {
			return '';
		}
		if (false === ['display', 'sort'].includes(type)) {
			return data;
		}
		let title = data.toString();
		if (title.length <= this) {
			return title;
		}
		if (type == 'display') {
			title = jQuery.fn.dataTable.render._html_encode(title);
			return `<span title="${title}">` + title.substr(0, this) + '&#8230;</span>';
		} else {
			return title.substr(0, this);
		}
	}

	/**
	 * Basic HTML-encoding function.
	 */
	jQuery.fn.dataTable.render._html_encode = function(text) {
		var entities = { 34 : '&quot;', 38: '&amp;', 60 : '&lt;', 62 : '&gt;' };
		return text.toString().replace(/["&<>]/gi, function(c) { return entities[c.charCodeAt()]; });
	}

	/**
	 * A shared function to parse strings for {token} tokens.
	 * If the token is in the format {column|function} the given render function will be called on the column value before replacing the token.
	 *
	 * @param   string      text            The text to parse for tokens.
	 * @param   array       data            An array of row data. Used to pass values to replace tokens.
	 * @return  string                      The parsed text.
	 */
	jQuery.fn.dataTable.render._untokenise = function(text, data) {
		var matches = [...text.matchAll(/{(\w+)(?:\|(\w+))*}/g)];
		if (matches == null) {
			return text;
		}
		$.each(matches, function(k, match) {
			let value = typeof data[match[1]] == 'undefined' ? '' : data[match[1]];
			if (typeof match[2] != 'undefined') {
				value = jQuery.fn.dataTable.render[match[2]](value, 'display', data, { row : 1 });
			}
			text = text.replace(match[0], value);
		});
		return text;
	};

	/**
	 * A shared function to return a value from a lookup table safely.
	 *
	 * @param   string      lookup          The lookup table to use.
	 * @param   string      key             The key of the lookup table to use.
	 * @return  string                      The value from the lookup table for the given key, or the key if the lookup failed.
	 */
	jQuery.fn.dataTable.render._lookup = function(lookup, key, empty = '') {
		if (empty == 'NA') {
			empty = jQuery.fn.dataTable.render.NA;
		}
		if ((key === undefined) || (key === null) || (key.length == 0)) {
			return empty;
		}
		if ((typeof jQuery.fn.dataTable.render._lookups != 'undefined')
			&& (typeof jQuery.fn.dataTable.render._lookups[lookup] != 'undefined')
			&& (typeof jQuery.fn.dataTable.render._lookups[lookup][key] != 'undefined')) {
			return jQuery.fn.dataTable.render._lookups[lookup][key];
		}
		return empty;
	};

	/**
	 * A shared function to determine whether the meta parameter has been passed by a cell().render() call.
	 * https://datatables.net/reference/option/columns.render#function
	 * Can be used by render functions, like datetime, to determine whether it is being called with user parameters or by cell().render().
	 *
	 * @param   mixed      	meta          	If passed by the cell().render() call, an object with a row parameter. If not, could be anything.
	 * @return  boolean                     Whether this was passed by the cell().render() call.
	 */
	jQuery.fn.dataTable.render._isRenderType = function(meta) {
		if (meta == null) {
			return false;
		}
		return (typeof meta == 'object') && (typeof meta.row == 'number');
	}
}

/**
* Rebuilds the table on tab change when a datatable is loaded within an inactive tab and the width exceeds the available space
*/
$(document).on('change.zf.tabs', function (event, tab) {
	$.each($(document).find('table.dataTable'), function(index, table) {
		$(this).trigger('rebuild.zt');
	});
});

/**
 *
 */
$(document).ready(function() {
	// Manage the selected ids
	let selectedIds = [];
	$(document)
		.on('select.zt', function(e, amount, ids) {
			selectedIds = ids;
		})
		.on('change', 'select.zat-table', function(e){
			$(this).blur();
			let $table;
			let target = $(this).data('target');
			if (typeof target == 'undefined') {
				$table = $('table.zat-table').eq(0);
			} else {
				$table = $('#' + target);
			}
			$table.DataTable().page.len($(this).val()).draw();
		})
		.on('click tap', '.search-clear', function(e) {
			$(this).parent().find('input').val('').keyup();
		})
		.on('click tap', '.deselect', function(e) {
			let $table = $(this).target();
			if (false === $table) {
				return;
			}
			$table.DataTable().rows({ selected: true }).deselect();
			$table.trigger('deselect.zt');
		})
		.on('click tap', 'a.zt-export', function(e) {
			e.preventDefault();
			let $table = $(this).target();
			if (false === $table) {
				return;
			}
			let api = $table.DataTable();
			let format = $(this).data('format');
			let name;
			if (typeof format == 'undefined') {
				name = 'csv';
			} else {
				name = format;
			}
			jQuery.fn.dataTable.zt.filename = ($table.data('buttonsFilename') !== undefined && $table.data('buttonsFilename') !== null) ? $table.data('buttonsFilename') : '*';
			jQuery.fn.dataTable.zt.title = ($table.data('buttonsTitle') !== undefined && $table.data('buttonsTitle') !== null) ? $table.data('buttonsTitle') : '*';
			let init = api.init();
			let serverSide = init.bServerSide;
			if (serverSide) {
				let visibility = api.columns().visible();
				let params = api.ajax.params();

				$.each(params.columns, function(k, column) {
					let oCol = api.column(`${column.data}:name`);
					let index = oCol.index();
					let $header = $(oCol.header());
					column.visible = visibility[index];
					column.header = $header.text();
					column.order = index;
					column.render = $header.attr('data-render') ? $header.attr('data-render') : null;
					column.renderParams = $header.attr('data-render-params') ? $header.attr('data-render-params') : null;
					// Clear some space?
					delete column.name;
					delete column.searchable;
					delete column.orderable;
				});

				params.rows = selectedIds || Array.from(api.rows({ selected : true }).data().pluck('id'));
				params.format = name;

				let query = $.param(params);

				if (name == 'map') {
					$(document).trigger('exportMap');
					return;
				}

				// Fix for when using the POST and the ajax is turned to an object
				// rather than a string: ZP-4827
				if (typeof init.ajax == 'object' && (init.ajax.type || null) == 'POST') {
					let $form = $(`<form style="display:none;" method="POST" action="${api.ajax.url()}/export"></form>`);
					appendFieldsRecursive($form, params);
					$form.appendTo('body')
						.submit()
						.remove();
					return;
				}

				window.location.href = api.ajax.url() + '/export?' + query;
			} else {
				api.button(name + ':name').trigger();
			}
		})
		// Handles click on a bulk action button, creates an array of row ids and triggers bulkaction.zt passing the target and those ids
		.on('click tap', '.bulk-action', function(e) {
			let $table = $(this).target();
			if (false === $table || $(this).prop('disabled')) {
				return;
			}
			let api = $table.DataTable();
			let serverSide = api.init().bServerSide;
			let ids;
			if (serverSide) {
				ids = $table.data('selected');
				if (ids.length == 0) {
					// Not a lot we can do here as we don't know all ids in the table
				}
			} else {
				ids = Array.from(api.rows({ selected : true }).data().pluck('id'));
				if (ids.length == 0) {
					ids = Array.from(api.rows({ search : 'applied' }).data().pluck('id'));
				}
			}
			$(this).trigger('bulkaction.zt', [ids]);
		})
		// Handle customisations reset
		.on('click tap', 'a.reset-visibility', function(e) {
			const tid = $(this).data('target');

			let api = $(`#${tid}`).DataTable();
			const visibility = $(`#${tid}`).data('visibility');

			visibility.forEach((value, idx) => {
				api.column(api.colReorder.transpose(idx)).visible(value);
				$(`#${tid}_colvis_${idx}`).prop('checked', value);
			});

			api.columns.adjust().draw();
			api.state.save();

			$(`#${tid}_settings`).foundation('close');
		})
		// Handle clearing of filters
		.on('click tap', 'a.clear-filters', function(e) {
			let api = $('#' + $(this).data('target')).DataTable();
			let init = api.init();
			let serverSide = init.bServerSide;

			if (serverSide) {
				api.search('');
			}

			$(this).trigger('clearfilters.zt', [$(this).data('target'), serverSide]);

			$('input.zat-table-search').val('').keyup();

			$(this).closest('div.zat-table-filters').foundation('close');
		})
		// Handle general search
		.on('keyup', 'input.zat-table-search', function(e) {
			const $table = $('#' + $(this).data('target'));
			const api = $table.DataTable();
			let value = $(this).val().length ? $.trim($(this).val()) : '';
			$table
				.data('search', value)
				.trigger('search.zt', [value]);
			$(this).onTimeout(function() {
				const value = $.trim($table.data('search'));
				api.search(value).draw();
			});
		});

	// Handle autoclose of the filter dropdown
	$(document)
		.on('show.zf.dropdown', 'div.zat-table-filters', function() {
			$(this).data('modal', true);
		})
		.on('hide.zf.dropdown', 'div.zat-table-filters', function() {
			$(this).data('modal', false);
		})
		.on('click', function(event) {
			$('div.zat-table-filters').each(function() {
				if ((!$(this).data('modal'))
					|| ($(event.target).closest('div.zat-table-filters').length)
					|| ($(event.target).hasClass('zat-table-reveal'))
					|| ($(this).data('persist'))) {
					return true;
				}
				$(this).foundation('close');
			});
		});

	// Handle date picker with name "dates"
	addDataTableDateFilter('input[name="dates"]');

	// Set up functions and values that require i18n, and need to wait for it to load
	if (typeof $.fn.dataTable != 'undefined') {
		jQuery.fn.dataTable.render.NA = '<span class="text-dark-gray">' + i18n.__('na') + '</span>';
	}
});

/**
 * Add fields to given form, if the field is an object, this gets nested
 * @param  object form
 * @param  object params
 * @param  string|null parent
 * @return void
 */
function appendFieldsRecursive(form, params, parent = null) {
	$.each(params, function(fieldName, value) {
		// If there is a parent - we next this field, else we are at a parent level
		fieldName = parent ? `${parent}[${fieldName}]` : fieldName;

		if (typeof value == 'object') {
			appendFieldsRecursive(form, value, fieldName);
			return;
		}

		// Append additional field to the form
		$('<input>').attr({
			type  : 'hidden',
			name  : fieldName,
			value : value || '',
		}).appendTo(form);
	});
}

/**
 * Helper function to handle a date range picker filter.
 * @param 	string 		selector 			The jQuery selector for the element.
 * @param 	object 		pickerOptions 		Configuration object for the datepicker.
 * @param 	function 	callback 			A filter function to handle the search. If null the default filter function will be used.
 * @return 	void
 */
window.addDataTableDateFilter = function(selector, pickerOptions = { 'opens':'center', 'drops':'up' }, callback = null) {

	let $dates = $('div.zat-table-filters').find(selector);

	if ($dates.length) {

		// Interact with the filters dropdown to ensure it stays open when we're using the date picker
		$dates
			.on('show.daterangepicker', function() {
				$('#' + $(this).data('target') + '_filters').data('persist', true);
			})
			.on('hide.daterangepicker', function() {
				$('#' + $(this).data('target') + '_filters').data('persist', false);
			});

		// Get a reference to the column this refers to. Uses the data-column attribute on the input
		let column = $dates.data('column');
		if (typeof column == 'undefined') {
			column = 'createdAt';
		}
		let dataSrc = column + ':name';

		// Hook in to the clear filters click to clear any set dates
		$('a.clear-filters').on('clearfilters.zt', function(e, target, serverSide) {
			if (serverSide) {
				let api = $('#' + $(this).data('target')).DataTable();
				api.column(dataSrc).search('');
			}
			$(selector).data('valid', false).val('');
		});

		// Add event handling to filter element
		const defaults = getDateRangePickerDefaults();
		$dates
			.data('valid', false)
			.daterangepicker(
				$.extend({}, defaults, pickerOptions)
			)
			.on('apply.daterangepicker', function(e, picker) {
				$(this).val(picker.startDate.format('Do MMMM YYYY') + ' - ' + picker.endDate.format('Do MMMM YYYY'));
				let api = $('#' + $(this).data('target')).DataTable();
				let value = picker.startDate.format('YYYY-MM-DD') + '|' + picker.endDate.format('YYYY-MM-DD');
				$(this).data('valid', true);
				let init = api.init();
				let serverSide = init.bServerSide;
				if (serverSide) {
					api.column(dataSrc).search(value).draw();
				} else {
					api.draw();
				}
				$('#' + $(this).data('target') + '_filters').foundation('close');
			})
			.on('cancel.daterangepicker', function(e, picker) {
				let api = $('#' + $(this).data('target')).DataTable();
				let value = '';
				$(this).data('valid', false);
				$(this).val(value);
				let init = api.init();
				let serverSide = init.bServerSide;
				if (serverSide) {
					api.column(dataSrc).search(value).draw();
				} else {
					api.draw();
				}
				$('#' + $(this).data('target') + '_filters').foundation('close');
			});

		// https://datatables.net/manual/plug-ins/search
		if (callback) {
			$.fn.dataTable.ext.search.push(callback);
		} else {
			$.fn.dataTable.ext.search.push(function(settings, searchData, index, rowData, counter) {
				var valid = $(selector).data('valid');
				if ((typeof valid == 'undefined') || (valid === false)) {
					return true;
				}
				var daterangepicker = $(selector).data('daterangepicker');
				if (typeof daterangepicker == 'undefined') {
					return true;
				}
				var min = daterangepicker.startDate;
				var max = daterangepicker.endDate;
				return moment(rowData[column]).isBetween(min, max);
			});
		}
	}
}

/**
 * Helper function to handle a dropdown filter.
 * @param 	node 		el 					The node of the dropdown.
 * @param 	string 		dataSrc 			The column selector, usually "<fieldname>:name".
 * @return 	void
 */
window.addDataTableDropdownFilter = function(el, dataSrc) {
	let api = $('#' + $(el).data('target')).DataTable();
	let init = api.init();
	let serverSide = init.bServerSide;

	if (serverSide) {
		let value = $(el).length > 0 && $(el).val() != '' ? $(el).val() : '';
		api.column(dataSrc).search(value, false).draw();
	} else {
		let value = $(el).length > 0 && $(el).val() != '' ? '^' + $(el).val() + '$' : '';
		api.column(dataSrc).search(value, value.length > 0, false).draw();
	}
	// Got a request to keep the filters open until clicked off
	// If we decide we want to close the dropdown pane on click again uncomment this line
	// $('#' + $(el).data('target') + '_filters').foundation('close');
}

/**
 * Helper function to handle a multiple values in a filter.
 * @param  node    el      The node of the dropdown.
 * @param  string  dataSrc The column selector, usually "<fieldname>:name".
 * @param  boolean asOr    Whether to do an OR search rather than an AND search.
 * @return void
 */
 window.addDataTableMultiValueFilter = function(el, dataSrc, multiFilter = false, multiSrc = true) {

 	let api = $('#' + $(el).data('target')).DataTable();
	let init = api.init();
	let serverSide = init.bServerSide;
	if (serverSide) {
		let value = $(el).val() > 0 ? $(el).val() : '';
		api.column(dataSrc).search(value).draw();
	} else {
		let value;
		if ($(el).length > 0 && $(el).val() != '') {
			const startChar = multiSrc ? '@' : '^';
			const endChar = multiSrc ? '@' : '$';
			if (multiFilter) {
				value = `${startChar}(` + $(el).val().join('|') + `)${endChar}`;
			} else {
				value = startChar + $(el).val() + endChar;
			}
		} else {
			value = '';
		}
		api.column(dataSrc).search(value, value.length > 0, false).draw();
	}
}

/**
 * Helper function to handle a dropdown filter for fuzzy search.
 * @param 	node 		el 					The node of the dropdown.
 * @param 	string 		dataSrc 			The column selector, usually "<fieldname>:name".
 * @return 	void
 */
window.addDataTableDropdownPlatformFilter = function(el, dataSrc) {
	let api = $('#' + $(el).data('target')).DataTable();

	let value = $(el).length > 0 && $(el).val() != '' ?  '^'  + '.*' + $(el).val() + '.*' : '';
	api.column(dataSrc).search(value, value.length > 0, false).draw();

}

/**
 * Helper function to handle a sites dropdown filter.
 * Table must have "groupId" and "siteId" fields. Select must have "target" data attribute specifying the table id.
 * @return 	void
 */
window.addDataTableSiteFilter = function(el) {
	let api = $('#' + el.data('target')).DataTable();
	let init = api.init();
	let dataSrc;
	// If it's a group expect "G-##" or "X-##" for global rather than "##". Ignore "0"
	let matches = el.val().match(/((G\-)|(X\-))*([1-9]\d*)/);
	if (matches && matches[1]) {
		if (matches[1] == 'G-') {
			dataSrc = 'groupId:name';
		} else {
			dataSrc = 'globalId:name';
		}
	} else {
		dataSrc = 'siteId:name';
	}

	let value = (matches == null ? '' : matches[4]);
	// Clear other field, in case we're currently filtered on it
	if (dataSrc == 'groupId:name') {
		api.column('siteId:name').search('');
		api.column('globalId:name').search('');
	} else if (dataSrc == 'globalId:name') {
		api.column('siteId:name').search('');
		api.column('groupId:name').search('');
	}  else {
		api.column('globalId:name').search('');
		api.column('groupId:name').search('');
	}
	let serverSide = init.bServerSide;
	if (serverSide) {
		api.column(dataSrc).search(value).draw();
	} else {
		let populated = value.length > 0;
		if (populated) {
			value = '^' + value + '$';
		}
		api.column(dataSrc).search(value, populated, false).draw();
	}
	$('#' + el.data('target') + '_filters').foundation('close');
}
