// Stops errors in pages not including DataTables. Maybe we should only include this if DataTables is included
if (typeof $.fn.dataTable != 'undefined') {

	jQuery.fn.dataTable.render.multiple_vehicles = function(data, type, row) {
		if (data == null) {
			return '';
		}
		const cleanData = data.replace(/<(?!br\s*\/?)[^>]+>/g, '').replace('Multiple', '').replaceAll('"', '');
		const vehicleArray = cleanData.split('<br>');
		if (type == 'csv' | type == 'export') {
			if (vehicleArray.length == 1) {
				return vehicleArray[0];
			} else {
				return vehicleArray.join(', ').slice(0, -2);
			}
		}
		return data;
	};

	jQuery.fn.dataTable.render.listolocations = function(data, type, row, listo) {
		if (data == null) {
			return '';
		}

		let params = { id: row.id, class: 'listo' };
		params.total = typeof row.locations == 'object' ? row.locations.length : 0;
		params.plural = params.total == 1 ? '' : 's';

		switch (type) {
			case 'display':
				if (params.total) {
					params.open = '<button class="text-inform-wcag" data-toggle="listolocations_' + row.id + '">';
					params.close = '</button>';
					const locations = row.locations.map(location => {
						var name = jQuery.fn.dataTable.render._lookup(location.type, location.id);
						return `<a href="/${location.type}/view/${location.id}">${name}</a>`;
					});
					params.info = locations.join('<br>');
				}
				return params.total == 0
					? '<span class="text-dark-gray">Unassigned</span>'
					: jQuery.fn.dataTable.render._untokenise($('#listolocations').html(), params);
			case 'filter':
				let locations = [];
				if (params.total == 0) {
					return '|0|unassigned|';
				} else {
					const locations = row.locations.map(location => {
						return jQuery.fn.dataTable.render._lookup(location.type, location.id);
					});
					return '|' + locations.join('|') + '|';
				}
			case 'sort':
				return params.total;
			default:
				return params.total == 0 ? 'Unassigned' : `${params.total} Location{params.plural}`;
		}
	};

	jQuery.fn.dataTable.render.listotariffs = function(data, type, row) {
		if (data == null) {
			return '';
		}

		let params = { id: row.id, class: 'listo' };
		params.total = typeof row.tariffs == 'object' ? row.tariffs.length : 0;
		params.plural = params.total == 1 ? '' : 's';

		switch (type) {
			case 'display':
				if (params.total) {
					params.open = '<button class="text-inform-wcag" data-toggle="listotariffs_' + row.id + '">';
					params.close = '</button>';
					const tariffs = row.tariffs.map(id => {
						var tariff = jQuery.fn.dataTable.render._lookup('tariffs', id);
						var type = jQuery.fn.dataTable.render._lookup('permit_types', tariff.type);
						return `<a href="/tariffs/view/${type}/${id}">${tariff.name}</a>`;
					});
					params.info = tariffs.join('<br>');
				}
				return params.total == 0
					? '<span class="text-dark-gray">Unassigned</span>'
					: jQuery.fn.dataTable.render._untokenise($('#listotariffs').html(), params);
			case 'filter':
				let tariffs = [];
				if (params.total == 0) {
					return '|0|unassigned|';
				} else {
					const tariffs = row.tariffs.map(id => {
						var tariff = jQuery.fn.dataTable.render._lookup('tariffs', id);
						return tariff.name;
					});
					return '|' + tariffs.join('|') + '|';
				}
			case 'sort':
				return params.total;
			default:
				return params.total == 0 ? 'Unassigned' : `${params.total} Tariff${params.plural}`;
		}
	};

	/**
	 * Renders a remove link
	 * @return string
	 */
	jQuery.fn.dataTable.render.removeLink = function(lang) {
		return function (data, type, row) {
			return type == 'display'
				? `<a href="javascript:void(0);" class="white-space-nowrap" data-remove-row-target>${lang}</a>` : '';
		};
	};
}
